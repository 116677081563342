<template>
  <div>
    <div class="h-fit w-100">
      <div
        v-if="!loaded || validToken"
        class="mt-4 fs-3 fw-bold underline text-center"
      >
        {{ $tcust('Register.title') }}
      </div>

      <div
        v-if="!loaded"
        class="w-fit m-auto mt-3 rounded-5 overflow-hidden"
      >
        <Loader />
      </div>

      <div
        v-else-if="!validToken"
        class="w-fit m-auto mt-5 fs-4 text-center"
      >
        <div
          class="w-100 mb-3"
          v-html="$tcust('Register.invalidToken')"
        />
        <button
          class="btn btn-primary fs-5 text-normal mx-2 mt-3"
          @click="$router.push({ name: 'SignIn' })"
        >
          <span>{{ $tcust('Register.goSignIn') }}</span>
        </button>
      </div>

      <CardContainer
        v-else
        class="gradient m-auto mt-4 h-fit"
      >
        <form
          id="registerForm"
          class="p-0"
        >
          <!-- Password -->
          <div
            class="inside-block"
            :class="PasswordConfirmChecked.check === false ? 'bg-warning' : ''"
          >
            <div class="bg-box">
              <PasswordConfirm
                class="p-3"
                @checked="PasswordConfirmChecked = $event"
              />
            </div>
          </div>

          <!-- Submit button -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="d-flex justify-content-center p-4">
                <MDBBtn
                  color="primary"
                  block
                  class="w-75 fw-bold"
                  :disabled="!PasswordConfirmChecked.check"
                  @click="checkSubmit"
                >
                  <span>{{ $tcust("Register.submit") }}</span>
                </MDBBtn>
              </div>
            </div>
          </div>
        </form>
      </CardContainer>      
    </div>
  </div>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit";
import PasswordConfirm from "@/components/form/PasswordConfirm";

import { ref } from "vue";
const PasswordConfirmChecked = ref({});

const checkSubmit = () => {
  if (PasswordConfirmChecked.value.check) submit(PasswordConfirmChecked.value.data)
};

import Setup from "./RegisterConfirmSetup";
const { validToken, submit, loaded } = Setup();
</script>
