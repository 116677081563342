<template>
  <div
    id="PasswordConfirm"
  >
    <!-- Password -->
    <MDBInput
      id="Password"
      v-model="Password"
      type="password"
      autocomplete="new-password"
      required
      pattern="^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"
      :label="$tcust('common.form.password')"
      class="text-center mb-3"
    />
    <!-- PasswordConfirm -->
    <MDBInput
      id="PasswordConfirm"
      v-model="PasswordConfirm"
      type="password"
      autocomplete="new-password"
      required
      pattern="^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$"
      :label="$tcust('common.form.passwordConfirm')"
      class="text-center"
    />
    <!-- Password failed -->
    <div v-if="PasswordFailed">
      <hr class="w-75 mx-auto mt-4">
      <div class="ps-2">
        <span v-html="$tcust('common.form.passwordRequirements')" />
        <ul>
          <li
            v-for="(item, index) in PasswordRequirementsList"
            :key="index"
          >
            {{ $tcust('common.form.passwordRequirementsList.' + item) }}
          </li>
        </ul>
      </div>
    </div>
    <!-- Confirmation failed -->
    <div v-if="PasswordConfirmFailed">
      <hr class="w-75 mx-auto mt-4">
      <div class="ps-2">
        <span v-html="$tcust('common.form.passwordConfirmFailed')" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { MDBInput } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { defineEmits, computed, ref, onMounted, watch } from "vue";

const emit = defineEmits(['checked']);
const store = useStore();
const clickPath = computed(() => {
  return store.state.app.clickPath;
});

const Password = ref("");
const PasswordConfirm = ref("");
const PasswordFailed = ref(false);
const PasswordConfirmFailed = ref(false);
const PasswordRequirementsList = [
  "number",
  "majLetter",
  "didgit",
  "special",
];

const checkPasswords = () => {
  if (!Password.value.match(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/)) {
    PasswordFailed.value = true;
    return false
  }
  else if (PasswordConfirm.value !== Password.value) {
    PasswordFailed.value = false;
    PasswordConfirmFailed.value = true;
    return false
  }
  else {
    PasswordFailed.value = false;
    PasswordConfirmFailed.value = false;
    return true
  }
};

const elem = ref(null);
const wasChecked = ref(false);
onMounted(() => {
  elem.value = document.getElementById('PasswordConfirm')
});
watch(clickPath, () => {
  if (clickPath.value.includes(elem.value)) wasChecked.value = true;
  else if (wasChecked.value) {
    emit('checked', {
      check: checkPasswords(),
      data: {
        "Password": Password.value,
        "PasswordConfirmation": PasswordConfirm.value,
      }
    })
    wasChecked.value = false;
  }
});
</script>
